@mixin e($element) {
    &__#{$element} {
        @content;
    }
}

@mixin m($modifier) {
    &--#{$modifier} {
        @content;
    }
}

$image-width: 100%;
$indicator-size: 7px;

.kingine-slider {
    position: relative;
    margin-bottom: 70px;
    width: 100%;

    .kingine-list {
        position: relative;
        //height: 100vh;
        //width: $image-width;
        //overflow: hidden;

        @include e('item') {
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            //float: left;
            width: $image-width;
            -webkit-transition: display 3s linear;
            transition: opacity 0.5s linear;

            img {
                width: 100%;
            }

            @include m('active') {
                opacity: 1;
                z-index: 10;
            }
        }
    }

    @include e('block') {
        width: $image-width;
        overflow: hidden;
        margin: 0 auto;
    }

    .kingine-indicator {
        position: absolute;
        bottom: 0;
        right: 0;
        overflow: hidden;
        z-index: 20;

        li {
            float: left;
            margin: 0 5px;
        }

        @include e('item') {
            a {
                width: $indicator-size;
                height: $indicator-size;
                border-radius: 50%;
                background-color: rgba(77, 77, 77, 1);
            }

            @include m('active a') {
                width: $indicator-size;
                height: $indicator-size;
                border-radius: 50%;
                background-color: rgba(195, 195, 195, 1);
                -webkit-animation: indicatorEffect 0.5s linear 1 alternate;
                animation: indicatorEffect 0.5s linear 1 alternate;
            }
        }
    }
}

@-webkit-keyframes  indicatorEffect {
    0% {background-color: crimson;}
    100% {background-color: rgba(195, 195, 195, 1);}
}

@keyframes  indicatorEffect {
    0% {background-color: crimson;}
    100% {background-color: rgba(195, 195, 195, 1);}
}
