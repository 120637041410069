.collection__menu--bottom {
    justify-content: flex-end !important;
    background-color: transparent !important;
}

@media (max-width:768px) {
	ul.pagination li:not(:first-child):not(:last-child) {
		display:none;
	}
}

.social{
    width: 350px;
}
 
.social-container{
    display: flex;
    justify-content: flex-end;
}
 
.area-section{
    width: 30%;
}
 
.heading-title{
    font-family:sans-serif;
    color: #717171;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .15em;
}
 
.area-list{
    padding: 20px;
    text-align: center;
    border: 1px solid rgba(101,101,101,0.5);
    border-radius: 3px;
}
