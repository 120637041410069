@mixin e($element) {
    &__#{$element} {
        @content;
    }
}

@mixin m($modifier) {
    &--#{$modifier} {
        @content;
    }
}

.kingine-shop-page {
    display: flex;
    justify-content: space-between;

    .sidebar {
        width: 300px;

        .widget {
            margin-bottom: 50px;
            padding: 0 20px;

            @include e('title') {
                border-bottom: 1px solid;
                font-size: 14px;
                font-weight: 600;
                margin-bottom: 15px;
                padding-bottom: 15px;
                letter-spacing: 0.1em;
            }

            .list {
                @include e('item') {
                    margin-bottom: 10px;

                    a {
                        display: inline-block;
                        color: #8f8f8f;
                        font-size: 12px;
                        text-decoration: none;
                        text-transform: capitalize;
                        letter-spacing: 0.05em;
                    }

                    @include m('active') {
                        color: #3cb5a7 !important;
                    }
                }
            }
        }
    }

    .content {
        width: 900px;

        .list {
            display: flex;
            flex-wrap: wrap;

            @include e('item') {
                width: 33%;

                a {
                    padding: 22px;
                    border: 2px solid transparent;
                    text-decoration: none;
                    transition: all 0.2s ease-in-out;
                    -webkit-transition: all 0.2s ease-in-out;

                    .media {
                        margin-bottom: 30px;
                    }

                    .detail {
                        display: flex;
                        justify-content: space-between;

                        @include e('title') {
                            color: #717171;
                            font-size: 12px;
                            font-weight: bold;
                            text-transform: uppercase;
                            letter-spacing: 0.15em;

                            span {
                                font-size: 12px;
                                font-weight: 400;
                                text-transform: capitalize;
                            }
                        }

                        @include e('price') {
                            color: #03b2a4;
                            font-size: 16px;
                            font-weight: bold;
                            text-transform: uppercase;
                        }
                    }
                }

                a:hover {
                    border: 2px solid #ececec;
                    border-radius: 3px;
                    -webkit-animation: shakeBox 0.1s ease-in-out 0.3s 2 alternate;
                            animation: shakeBox 0.1s ease-in-out 0.3s 2 alternate;
                }

                @-webkit-keyframes shakeBox{
                    from {
                        -webkit-transform: rotate(2deg);
                                transform: rotate(2deg);
                    }
                    to {
                        -webkit-transform: rotate(-4deg);
                                transform: rotate(-4deg);
                    }
                }

                @keyframes shakeBox{
                    from {
                        -webkit-transform: rotate(2deg);
                                transform: rotate(2deg);
                    }
                    to {
                        -webkit-transform: rotate(-4deg);
                                transform: rotate(-4deg);
                    }
                }
            }
        }
    }
}
