@mixin e($element) {
    &__#{$element} {
        @content;
    }
}

@mixin m($modifier) {
    &--#{$modifier} {
        @content;
    }
}

$product-width: 180px;

.kingine-shop-slider {
    position: relative;
    margin-bottom: 70px;

    @include e('block') {
        position: relative;
        z-index: 2;
        width: 95%;
        overflow: hidden;
        margin: 0 auto;
    }

    .kingine-list {
        width: 99999px;
        overflow: hidden;

        @include e('item') {
            float: left;

            a {
                width: $product-width;
                padding: 24px;
                border-radius: 3px;
                text-decoration: none;
                -webkit-transition: all 0.2s ease-in-out;
                        transition: all 0.2s ease-in-out;
            }

            a:hover {
                background-color: #f9f9f9;
            }
        }
    }

    .kingine-media {
        margin-bottom: 15px;
    }

    .kingine-item__details {
        display: flex;
        justify-content: space-between;

        @include m('title') {
            color: #717171;
            font-size: 12px;
            font-weight: bold;
            text-transform: uppercase;
            letter-spacing: 0.15em;

            span {
                font-size: 12px;
                font-weight: 400;
                text-transform: capitalize;
            }
        }

        @include m('price') {
            color: #03b2a4;
            font-size: 16px;
            font-weight: bold;
            text-transform: uppercase;
        }
    }

    .kingine-direction {
        position: absolute;
        bottom: 0;
        z-index: 1;
        width: 100%;

        @include e('previous') {
            float: left;
            color: #afafaf;
        }

        @include e('next') {
            float: right;
            color: #afafaf;
        }
    }
}
