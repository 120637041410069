//@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

@mixin e($element) {
    &__#{$element} {
        @content;
    }
}

@mixin m($modifier) {
    &--#{$modifier} {
        @content;
    }
}

$footer-color: #45140e;

@font-face {
    font-family: Apercu;
    src: url(/vendor/fonts/apercu_regular-webfont.woff2) format("woff2");
    font-weight: 400;
    font-style: normal
}

@font-face {
    font-family: Apercu;
    src: url(/vendor/fonts/apercu_bold-webfont.woff2) format("woff2");
    font-weight: 700;
    font-style: normal
}

@font-face {
  font-family: 'GillSans';
  src: url("/vendor/fonts/GillSans.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

// * {
//     box-sizing: border-box;
// }

html {
    font-family: sans-serif;
    display: table;
    width: 100%;
}

body {
    font-size: 12px;
    //font-family: Helvetica,Arial,sans-serif;
    font-family: Apercu,Gill Sans MT,Gill Sans,Arial,sans-serif;
}

main {
    padding-bottom: 100px;
}

img {
    display: block;
}

button {
    padding: 0;
    border: none;
    background-color: rgba(255, 255, 255, 0);
    cursor: pointer;
}

a {
    display: block;
}

.container {
    width: 1200px;
    //overflow: hidden;
    margin: 0 auto;
}

header {
    .menu {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        padding: 20px 0;
    }

    .primary-menu {
        display: flex;
        justify-content: space-between;
        list-style-type: none;
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 0.1em;

        li {
            position: relative;
        }

        @include e('item a') {
            padding: 5px 10px;
            color: #8f8f8f;
            //font-size: 12px;
            //font-weight: bold;
            text-decoration: none;
            //letter-spacing: 1.5px;
        }

        a:hover {
            background-color: #f5f5f5;
        }
    }

    .secondary-menu {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 20px;

        .header-social {
            display: flex;
            justify-content: flex-end;
            margin-right: 30px;
            list-style-type: none;

            @include e('item a') {
                margin-left: 10px;
            }
        }

        .header-search {
            display: flex;
            align-items: center;

            @include e('input') {
                width: 220px;
                height: 24px;
                padding: 0 0 0 10px;
                border: none;
                border-radius: 10px 0 0 10px;
                background: #eaeaea;
                background: -moz-linear-gradient(top, #eaeaea 0%, #ffffff 100%);
                background: -webkit-linear-gradient(top, #eaeaea 0%,#ffffff 100%);
                background: linear-gradient(to bottom, #eaeaea 0%,#ffffff 100%);
                font-weight: bold;
            }
        }
    }

    .dropdown-1 {
        .dropdown-toggle {
            @include e('active') {
                background-color: #f5f5f5;
            }
        }

        .dropdown-menu {
            display: none;
            position: absolute;
            top: 40px;
            right: 0;
            z-index: 30;
            min-width: 100px;
            max-width: 200px;
            word-break: break-all;
            box-shadow: 0 3px 20px rgba(0, 0, 0, 0.2);

            li {
                width: 180px;
                border-bottom: 1px solid #b5bcbc;
            }

            a {
                padding: 15px 0 11px;
                background-color: #fff;
                color: #b5bcbc;
                font-size: 12px;
                //font-weight: bold;
                text-align: center;
                text-decoration: none;
                text-transform: uppercase;
                letter-spacing: 1.5px;
            }

            a:hover {
                background-color: #b5bcbc;
                color: #fff;
            }

            @include e('active') {
                display: block;
            }
        }
    }

    .dropdown-2 {
        .dropdown-toggle {
            @include e('active') {
                background-color: #f5f5f5;
            }
        }

        .dropdown-slider {
            display: none;
            position: absolute;
            top: 40px;
            right: 0;
            z-index: 30;
            width: 519px;
            background: rgba(255,255,255,1);
            background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(223,223,223,1) 52%, rgba(222,222,222,0.99) 54%, rgba(224,224,224,0.92) 68%, rgba(255,255,255,0.77) 100%);
            background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,1)), color-stop(52%, rgba(223,223,223,1)), color-stop(54%, rgba(222,222,222,0.99)), color-stop(68%, rgba(224,224,224,0.92)), color-stop(100%, rgba(255,255,255,0.77)));
            background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(223,223,223,1) 52%, rgba(222,222,222,0.99) 54%, rgba(224,224,224,0.92) 68%, rgba(255,255,255,0.77) 100%);
            background: -o-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(223,223,223,1) 52%, rgba(222,222,222,0.99) 54%, rgba(224,224,224,0.92) 68%, rgba(255,255,255,0.77) 100%);
            background: -ms-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(223,223,223,1) 52%, rgba(222,222,222,0.99) 54%, rgba(224,224,224,0.92) 68%, rgba(255,255,255,0.77) 100%);
            background: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(223,223,223,1) 52%, rgba(222,222,222,0.99) 54%, rgba(224,224,224,0.92) 68%, rgba(255,255,255,0.77) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0 );
            box-shadow: 0 3px 20px rgba(0,0,0,0.2);

            @include e('active') {
                display: flex;
                align-items: center;
            }

            @include e('container') {
                overflow: hidden;
                margin: 0 30px;
            }
        }

        .dropdown-menu {
            width: 9999px;
            overflow: hidden;
            word-break: break-all;

            li {
                float: left;
            }

            a {
                padding: 15px 15px 10px;

                // span {
                //     display: block;
                //     margin-bottom: 5px;
                //     color: #8f8f8f;
                //     font-size: 12px;
                //     font-weight: bold;
                //     text-decoration: none;
                //     text-align: center;
                //     letter-spacing: 1.5px;
                // }
            }
        }
    }
}

footer {
    padding: 30px 0;
    background: url(/images/footer-bg.png) center repeat-x;
    background-size: cover;
    display: table;
    width: 100%;

    nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
        padding-bottom: 30px;
        border-bottom: 1px solid $footer-color;

        .menu {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 400px;

            @include e('block') {
                @include m('title') {
                    margin-bottom: 10px;
                    padding-bottom: 10px;
                    border-bottom: 1px solid #45140e;
                    color: #45140e;
                    font-size: 14px;
                    font-weight: 900;
                    letter-spacing: 0.15em;
                    text-transform: uppercase;
                }

                .menu-col {
                    @include e('item a') {
                        margin-bottom: 10px;
                        color: $footer-color;
                        font-size: 12px;
                        text-decoration: none;
                        letter-spacing: 0.15em;
                        text-transform: uppercase;
                    }
                }
            }
        }

        .social {
            @include m('title') {
                margin-bottom: 10px;
                padding-bottom: 10px;
                border-bottom: 1px solid $footer-color;
                color: $footer-color;
                font-size: 14px;
                font-weight: 900;
                letter-spacing: 0.15em;
                text-transform: uppercase;
            }

            // .social-row {
            //     display: flex;

            //     @include e('item') {
            //         padding: 0 10px;

            //         a:hover {
            //             opacity: 0.7;
            //         }
            //     }
            // }
        }
    }

    .copyright {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;

        @include e('text') {
            color: $footer-color;
            letter-spacing: 0.15em;
        }

        @include e('button') {
            position: absolute;
            top: -80px;
            right: 0;

            a {
                display: flex;
                align-items: center;
                justify-content: center;
                color: #bdbdbd;
                font-weight: bold;
                letter-spacing: 0.15em;
                text-decoration: none;
                background-color: #fdeee2;
                width: 60px;
                height: 50px;
                border-radius: 10px 10px 0 0;
            }

            a:hover {
                color: #46140c;
            }
        }
    }
}

#home {
    .kingine-heading {
        margin-bottom: 10px;

        @include e('text') {
            display: inline-block;
            width: 155px;
            padding: 7px 0 6px;
            border-radius: 15px;
            color: #fff;
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 0.25em;
            text-align: center;
        }
    }

    .discover-01 {
        background: url(/images/line-dots-01.png) center repeat-x;

        .discover-02 {
            background-color: #03b2a4;
        }
    }

    .popular-01 {
        background: url(/images/line-dots-02.png) center repeat-x;
        .popular-02 {
            background-color: #e87e32;
        }
    }
}

#collection,
#category,
#search {
    .banner {
        position: relative;
        // width: 1200px;
        // height: 376px;
        overflow: hidden;
        //background-image: url(/images/collection-banner.jpg);
        background-size: 1200px auto;

        .block {
            position: absolute;
            top: 36px;
            right: 87px;
            width: 600px;
        }

        @include e('title') {
            margin-bottom: 5px;
            color: #622821;
            font-size: 40px;
            font-weight: bold;
            text-transform: uppercase;
            text-align: center;
            letter-spacing: 0.25em;
        }

        @include e('content') {
            color: #7d7d7d;
            font-size: 14px;
            text-align: center;
            line-height: 1.3;
            letter-spacing: 1px;
        }
    }

    .menu {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        margin: 30px 0;
        padding: 0 20px;
        background-color: #e5e5e5;

        .breadcrumb-list {
            overflow: hidden;
            font-size: 12px;
            text-transform: uppercase;
            letter-spacing: 0.1em;

            @include e('item') {
                float: left;
                padding: 0 5px;
                color: #000;

                i {
                    padding: 0 10px;
                }

                @include m('active') {
                    float: left;
                    color: #29afa1;
                }
            }
        }
    }

    .pagination {
        overflow: hidden;

        li {
            float: left;

            a {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 30px;
                height: 30px;
                margin: 0 5px;
                border: 1px solid #e5e5e5;
                background-color: #fff;
                color: #000;
                font-weight: 600;
                text-decoration: none;
            }
   
            span {
                background-color: #313131 !important;
                color: #fff !important;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 30px;
                height: 30px;
                margin: 0 5px;
                border: 1px solid #e5e5e5;
                font-weight: 600;
            }
        }
    }
}

#item {
    .menu {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        margin: 30px 0;
        padding: 0 20px;
        background-color: #e5e5e5;

        .breadcrumb-list {
            overflow: hidden;
            font-size: 12px;
            text-transform: uppercase;
            letter-spacing: 0.1em;

            @include e('item') {
                float: left;
                padding: 0 5px;
                color: #000;

                i {
                    padding: 0 10px;
                }

                @include m('active') {
                    float: left;
                    color: #29afa1;
                }
            }
        }
    }

    .share-menu {
        margin-top: 40px;

        .share-list {
            display: flex;
            justify-content: center;
            align-items: center;

            .share-label {
                color: #8D8282;
                font-size: 14px;
                font-weight: bold;
                letter-spacing: 0.1em;
                text-transform: uppercase;
            }

            li {
                padding: 0 10px;
            }
        }
    }

    #m-content {
        display: none;
    }
}

#news {
    padding-top: 50px;

    .news-block {
        display: flex;
        justify-content: space-between;
    }

    .news-item {
        position: relative;
        width: 800px;
        margin: 0 auto 80px;
        padding: 40px 0 50px;
        text-align: center;
        //border: 1px solid #b4b4b4;
    }

    .news-item:hover {
        //border: 1px solid #622821;
    }

    .news-date {
        position: absolute;
        top: 10px;
        right: 15px;
        color: #cacaca;
        font-size: 14px;
        font-style: italic;
    }

    .news-title {
        margin: 0 auto 20px;
        color: #622821;
        font-size: 20px;
        width: 500px;
        text-transform: uppercase;
        text-align: center;
        letter-spacing: 0.25em;
        line-height: 1.5em;
    }

    img {
        margin-bottom: 20px;
    }

    .news-detail {
        padding: 0 50px;
        color: #7d7d7d;
        font-size: 14px;
        text-align: center;
        line-height: 1.3;
        letter-spacing: 1px;
    }

    .news-button {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 10px 0;
        background-color: #622821;
        color: #fff;
        font-size: 16px;
        letter-spacing: 0.15em;
        text-decoration: none;
        text-align: center;
    }

    .news-button:hover {
        opacity: 0.8;
    }

}

#outlets {
    font-size: 11px;

    .outlets-title {
        margin-bottom: 20px;
        color: #622821;
        font-size: 1.5em;
        text-transform: uppercase;
        text-align: center;
        letter-spacing: 0.25em;
    }

    .category-links {
        display: flex;
        justify-content: center;
        margin-bottom: 50px;

        li {
            padding-right: 10px;
        }

        a {
            color: #6d6d6d;
            font-size: 1em;
            text-transform: uppercase;
        }
    }

    .outlets-category {
        margin-bottom: 20px;
        color: #622821;
        font-size: 1.3em;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 0.15em;
    }

    .outlets-row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        margin-bottom: 50px;
    }

    .outlets-table {
        width: 380px;
        margin-bottom: 50px;

        thead {
            border: 1px solid #622821;
        }

        tbody {
            border: 1px solid #ccc;
        }

        th {
            padding: 7px 5px;
            background-color: #622821;
            color: #fff;
            font-size: 1em;
            letter-spacing: 0.12em;
        }
    }

    .city {
        padding: 0 6px;
        width: 30%;
        vertical-align: middle;
        border-right: 1px solid #ccc;
        background-color: #f9f9f9;
        font-size: 1em;
        font-weight: 700;
        text-align: center;
        text-transform: uppercase;
    }

    .outlets-table tr:nth-child(2n) .city {
        border-bottom: 1px solid #ccc;
        background-color: #eaeaea;
    }

    .store {
        width: 70%;
        border-bottom: 1px solid #ccc;
        font-size: 1em;

        li {
            padding: 6px 5px;
        }

        li:not(:last-child) {
            border-bottom: 1px solid #ccc;
        }
    }
}

#size {
    .size-title {
        margin-bottom: 20px;
        color: #622821;
        font-size: 40px;
        font-weight: bold;
        text-transform: uppercase;
        text-align: center;
        letter-spacing: 0.25em;
    }

    .size-row {
        display: flex;
        justify-content: space-around;
    } 
}

#contact {
    .contact-title {
        margin: 60px 0 20px;
        color: #622821;
        font-size: 18px;
        text-transform: uppercase;
        text-align: center;
        letter-spacing: 0.25em;
    }

    .contact-address {
        margin: 0 auto 20px;
        color: #622821;
        width: 400px;
        text-transform: uppercase;
        letter-spacing: 0.15em;
        line-height: 2;
    }

    .contact-info {
        margin: 0 auto 20px;
        color: #622821;
        width: 400px;
        text-transform: uppercase;
        letter-spacing: 0.15em;
        line-height: 4;
    }

    .contact-description {
        color: #622821;
        font-size: 14px;
        text-align: center;
        margin-bottom: 60px;
    }

    .contact-body {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
}

// tablet

@media screen and (max-width: 768px) {
    body,
    html {
        width: 768px;
    }
    .container {
        width: 684px !important;
    }
    .menu-logo {
        position: relative !important;
        top: 25px !important;
        z-index: -1;
    }
    .menu-logo img {
        width: 220px !important;
    }
    .menu-logo a {
        text-align: center !important;
    }
    header .menu {
        display: block !important;
    }
    header .dropdown-2 .dropdown-slider {
        right: -100px !important;
    }
    #home {
        .kingine-shop-slider {
            overflow: hidden !important;
        }
        .kingine-shop-slider__block {
            overflow: hidden;
            width: 684px !important;
        }
        .kingine-direction {
            display: none !important;
        }

        .kingine-slider .kingine-list__item img {
            width: 768px !important;
        }
    }

    #category,
    #collection,
    #search {
        .kingine-shop-page .content .list__item {
            width: 50% !important;
        }
        .media img {
            width: 100% !important;
        }
    }

    #news {
        .news-block {
            display: block !important;
        }

        .news-item {
            width: initial !important;
        }

        .widget {
            display: none !important;
        }
    }

    #item {
        .kingine-item-page {
            flex-wrap: wrap;
        }
        #m-content {
            display: block;
            width: 600px;
        }
        #p-content {
            display: none;
        }
        .share-menu {
            margin-bottom: 50px;
        }
        .media {
            display: flex !important;
            flex-wrap: wrap !important;
            margin-bottom: 50px;
        }
        .media-list__item a {
            width: 120px !important;
            height: 120px !important;
        }
        .media__preview {
            width: 532px !important;
            height: 532px !important;
            margin: 0 auto;
        }
        .kingine-item-page .media .media-list {
            flex-wrap: wrap;
            width: 150px !important;
        }
    }
}


// mobile

@media screen and (max-width: 480px) {
    body,
    html {
        width: 480px;
    }
    header .secondary-menu {
        position: absolute;
        top: 5px;
        right: 45px;
    }
    header nav.menu {
        position: relative;
    }
    header .primary-menu {
        flex-wrap: wrap;
        justify-content: center;
        position: relative;
    }
    header .primary-menu li {
        position: initial;
    }
    header .dropdown-1 .dropdown-menu {
        top: 55px !important;
        right: 137px !important;
    }
    header .dropdown-2 .dropdown-slider {
        width: 390px !important;
    }
    header .dropdown-2 .dropdown-slider {
        top: 55px !important;
        right: 35px !important;
    }
    .menu-logo {
        margin: 50px 0 30px;
        position: initial !important;
        top: 0 !important;
    }
    .menu-logo img {
        margin: 0 auto;
    }
    .container {
        width: 456px !important;
    }
    footer  nav {
        flex-wrap: wrap;
        justify-content: space-around !important;
    }
    footer .social {
        margin: 50px 0;
    }
    footer .copyright {
        display: block;
        text-align: center;
    }
    footer .copyright__text {
        margin: 30px 0;
    }
    #home {
        .kingine-slider .kingine-list__item img {
            width: 480px !important;
        }
        .kingine-shop-slider__block {
            overflow: hidden;
            width: 456px !important;
        }
        .kingine-direction {
            display: none !important;
        }
    }

    #category,
    #collection,
    #search {
        .kingine-shop-page .content .list__item {
            width: 100% !important;
        }
        .media img {
            width: 100% !important;
        }
    }

    #news {
        .news-title {
            width: auto !important;
        }
    }

    #item {
        .kingine-item-page {
            flex-wrap: wrap;
        }
        #m-content {
            display: block;
            width: 400px;
        }
        #p-content {
            display: none;
        }
        .share-menu {
            margin-bottom: 50px;
        }
        .media-list__item a {
            width: 120px !important;
            height: 120px !important;
        }
        .media__preview {
            width: 400px !important;
            height: 400px !important;
            margin: 0 auto;
        }
        .kingine-item-page .media .media-list {
            width: 400px !important;
            flex-wrap: nowrap;
            margin: 0 auto;
        }
    }
}

.social{
    width: 350px;
}

.social-container{
    display: flex;
    justify-content: flex-end;
}