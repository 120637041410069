@mixin e($element) {
    &__#{$element} {
        @content;
    }
}

@mixin m($modifier) {
    &--#{$modifier} {
        @content;
    }
}

.kingine-item-page {
    display: flex;
    justify-content: space-around;
    margin: 70px 0 30px;

    .media {
        .media-list {
            display: flex;
            justify-content: center;
            width: 600px;
            flex-wrap: wrap;

            @include e('item') {
                padding: 10px 10px;
            }
        }
    }

    .content {
        @include m('button') {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100px;
            height: 20px;
            border: 1px solid;
            color: #000;
            font-size: 14px;
            font-weight: 600;
            text-decoration: none;
        }

        @include e('title') {
            margin-bottom: 5px;
            font-size: 30px;
            font-weight: 600;
            text-transform: uppercase;
        }

        .color {
            margin-bottom: 30px;
            color: #a4a4a4;
            font-size: 20px;
        }

        .price {
            margin-bottom: 30px;
            color: #29afa1;
            font-size: 20px;
        }

        .size-block {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;

            .size {
                font-size: 14px;
                font-weight: 600;
            }
        }

        .sub-item {
            @include e('title') {
                margin-bottom: 10px;
                font-size: 14px;
                font-weight: 600;
            }

            .sub-item-list {
                display: flex;
                flex-wrap: wrap;
                width: 400px;
                margin-bottom: 20px;

                @include e('item') {
                    padding: 5px;
                }
            }
        }

        .outlet-block {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 30px;
        }

        .collection-block {
            display: flex;
            justify-content: flex-start;
            padding-top: 30px;
            border-top: 1px solid;

            .collection-logo {
                margin-right: 20px;
            }

            @include e('content') {
                .content__title {
                    font-size: 14px;
                    font-weight: 600;
                }

                .content__description {
                    width: 200px;
                    color: #828282;
                    line-height: 1.3;
                    font-size: 14px;
                }
            }
        }
    }
}
